import * as types from './types';

export default {
  [types.SET_ZONES_OF_MINE](state, zones) {
    state.zones = zones;
  },
  [types.SET_TARGET_OF_ZONE](state, target) {
    state.target = target;
  },
  [types.SET_PAGINATION](state, pagination) {
    state.pagination = pagination;
  },
  [types.SET_TARGETS](state, targets) {
    state.target = targets;
  },
  [types.SET_ACTIVE_HOURS](state, activeHours) {
    state.activeHours = activeHours;
  },
  [types.SET_PLAN_HOURS](state, planHours) {
    state.planHours = planHours;
  },
  [types.SET_PLAN_HOUR](state, planHour) {
    state.planHour = planHour;
  },
};