import driverApi from '@/api/driver';
import * as types from './types';

export default {
  getDrivers({ commit }, params) {
    return driverApi.findAll(params)
      .then((response) => {
        commit(types.SET_DRIVERS, response.data.data);
        commit(types.SET_PAGINATION, response.data.meta);
        return response;
      }).catch((error) => error)
  },
  getDriverById({ commit }, driverId) {
    return driverApi.findById(driverId)
      .then((response) => {
        commit(types.SET_DRIVER, response.data.data);
        return response;
      }).catch((error) => error)
  },
  getUnlinkedDrivers(context) {
    return driverApi.findUnlikedDrivers()
      .then((response) => {
        context.commit(types.UNLINKED_DRIVERS, response.data);
        return response;
      }).catch((error) => error)
  },
  createDriver(context, payload) {
    return driverApi.create(payload)
      .then((response) => response)
      .catch((error) => error)
  },
  updateDriver({ commit }, payload) {
    return driverApi.update(payload)
      .then((response) => {
        commit(types.UPDATE_DRIVER, response.data);
        return response;
      }).catch((error) => error)
  },
  deleteDriver({ commit }, driverId) {
    return driverApi.delete(driverId)
      .then((response) => {
        commit(types.DELETE_DRIVER, response.data);
        return response;
      }).catch((error) => error)
  },
  getPerformance({ commit }, data) {
    return driverApi.getPerformances(data)
      .then((response) => {
        console.log("we're here : ", response.data);
        
        commit(types.SET_PERFORMANCE, response.data.total_tonnage)
        commit(types.SET_ACTUAL_TOUR, response.data.number_of_tours)
      }).catch((error) => error);
  },
  getActualTonnageAndTours({ commit }) {
    return driverApi.getActualTonnageAndTours()
     .then((response) => {      
        commit(types.SET_ACTUAL_TONNAGE, response.data.actual_tonnage);
        commit(types.SET_ACTUAL_TOUR, response.data.actual_tour);

        return response;
      }).catch((error) => error);
  }
};