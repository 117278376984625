import { HTTP } from '@/http/index';

export default {
  findAll(params) {
    return HTTP.get('/dumptrucks', { params });
  },
  findById(dumpTruckId) {
    return HTTP.get(`/dumptrucks/${dumpTruckId}`);
  },
  findUnlikedDumpTrucks() {
    return HTTP.get('/unlinked-dumptrucks');
  },
  create(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.createDumpTruck',
      successKey: 'alert.success.createDumpTruck',
    };
    return HTTP.post('/dumptrucks', payload, { alertMessages });
  },
  update(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.updateDumpTruck',
      successKey: 'alert.success.updateDumpTruck',
    };
    return HTTP.put(`/dumptrucks/${payload.dumpTruckId}`, payload.data, { alertMessages });
  },
  delete(dumpTruckId) {
    const alertMessages = {
      errorKey: 'alert.errors.deleteDumpTruck',
      successKey: 'alert.success.deleteDumpTruck',
    };
    return HTTP.delete(`/dumptrucks/${dumpTruckId}`, { alertMessages });
  },
  unboundDumpTruck() {
    const alertMessages = {
      errorKey: 'alert.errors.unboundDumTruck',
      successKey: 'alert.success.unboundDumTruck',
    };
    return HTTP.post(`/release-driver`, { alertMessages })
  },
  unboundMore(payload) {
    const alertMessages = {
      errorKey: 'alert.errors.unboundMore',
      successKey: 'alert.success.unboundMore',
    };
    return HTTP.post('/drivers/unlink', payload, { alertMessages });
  }
};