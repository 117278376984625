export const SET_DRIVERS = 'SET_DRIVERS'
export const SET_DRIVER = 'SET_DRIVER'
export const UPDATE_DRIVER = 'UPDATE_DRIVER'
export const DELETE_DRIVER = 'DELETE_DRIVER'
export const UNLINKED_DRIVERS = 'UNLINKED_DRIVERS'
export const SET_PAGINATION = 'SET_PAGINATION'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_PERFORMANCE = 'SET_PERFORMANCE'
export const SET_ACTUAL_TONNAGE = 'SET_ACTUAL_TONNAGE'
export const SET_ACTUAL_TOUR = 'SET_ACTUAL_TOUR'
