import { HTTP } from '@/http/index';

export default {
    fetchAll() {
        return HTTP.get('/zones-all');
    },
    getTonnage(data) {
        return HTTP.post('/tonnage-by-zone-and-period', data);
    },
    getActualTonnageAndTours() {
        return HTTP.get('/actual-tonnage-and-tours');
    }
}