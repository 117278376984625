import zoneApi from '@/api/zone';
import * as types from './types';

export default {
    fetchAllZones({ commit }) {
        return zoneApi.fetchAll().then((response) => {
            commit(types.SET_ZONES, response.data);
        });
    },
    getTonnage({ commit}, data) {
        return zoneApi.getTonnage(data).then((response) => {
            commit(types.SET_TONNAGE, response.data.total_tonnage);
            commit(types.SET_ACTUAL_TOUR, response.data.total_of_tours);
        });
    },
    getActualTonnageAndTours({ commit }) {
        return zoneApi.getActualTonnageAndTours()
         .then((response) => {      
            commit(types.SET_TONNAGE, response.data.actual_tonnage);
            commit(types.SET_ACTUAL_TOUR, response.data.actual_tour);
    
            return response;
          }).catch((error) => error);
      }
}